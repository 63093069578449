.event-card {
    background-color: #BBDEFB; /* Light blue background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Shadow */
    margin-bottom: 16px; /* Bottom margin */
}

.event-card-content {
    padding: 16px; /* Padding for content */
}

.event-card-title {
    font-weight: bold; /* Bold font weight */
    color: #0D47A1; /* Dark blue title color */
}

.event-card-details {
    margin-top: 8px; /* Top margin */
    color: #546E7A; /* Dark grey text color */
}

