@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif; /* Use Roboto font for the entire page */
    background-color: #E3F2FD !important; /* Light blue background color */
    color: #37474F; /* Dark grey font color */
}

.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensure container takes up full height of viewport */
    margin-top: 0px !important;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.content-container {
    flex: 1; /* Content container takes up remaining space */
    margin-top: 5px;
    margin-bottom: 48px;
    background-color: #90CAF9; /* Lighter blue background color for content */
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.281); /* Shadow for content */
    padding: 16px; /* Add padding for content */
    border-radius: 8px 8px 0 0;
}

.bottom-navigation {
    width: 100%; /* Set width to 100% minus padding */
    background-color: #0D47A1 !important; /* Dark blue */
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1); /* Shadow */
    position: fixed; /* Fixed position */
    bottom: 0; /* Position at the bottom */
    left: 4px; /* Left padding */
    right: 4px; /* Right padding */
    z-index: 999; /* Ensure it's above other content */
    color: #FFFFFF; /* White color */
}

.bottom-navigation-action {
    color: #FFFFFF; /* White color */
}

/* Ensure label is always visible */
.MuiBottomNavigationAction-label {
    opacity: 1 !important;
}

/* Additional styling for text elements */
h1, h2, h3, h4, h5, h6 {
    color: #0D47A1; /* Dark blue text color */
}

p {
    color: #546E7A; /* Dark grey text color */
}

/* Default state */
.bottom-navigation-action {
  color: #FFFFFF; /* White color */
}

/* Hover state */
.bottom-navigation-action:hover {
  color: #BDBDBD; /* Light grey color */
}

/* Selected state */
.Mui-selected {
  color: #F5F5F5; /* Lighter shade of grey */
}

/* Selected and Hover state */
.Mui-selected.bottom-navigation-action:hover {
  color: #E0E0E0; /* Even lighter shade of grey */
}

.custom-app-bar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000; /* Ensure it's above other content */
  background-color: #0D47A1 !important; /* Dark blue background color */
}

.custom-app-bar .custom-app-bar-title {
  flex-grow: 1;
  color: #FFFFFF; /* White font color */
  text-align: center; /* Center align the title */
}

.MuiCollapse-root {
  margin-bottom: 20px;
}
.MuiContainer-maxWidthMd {
  max-width: none !important; /* or set a larger value, e.g., max-width: 1200px; */
}